
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";

import DateRangePicker from "@/components/form-components/DateRangePicker.vue";
import InputRange from "@/components/form-components/InputRange.vue";

export default Vue.extend({
  name: "Payments",

  mixins: [tablePagination, tableFilters],

  components: {
    DateRangePicker,
    InputRange
  },

  data: () => ({
    parseArrayParams,
    filter: {
      type: [],
      author: []
    } as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    selects: {
      payments: [],
      authors: []
    } as any,
    loading: false as boolean,
    showMenu: false,
    x: 0,
    y: 0,
    activeItemId: null
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 50
        }
      };
      const serviceFilter: any = {
        specialist_aaij: 2,
        specialist_ata: 1
      };
      const alias = store.getters["user/currentRole"]?.alias;

      if (Object.keys(serviceFilter).includes(alias)) {
        params.filter.service_types = String(serviceFilter[alias]);
      }

      const response = await API.payments().getData(params);
      const authorList = await API.payments().getAuthors();
      const serviceTypes = await API.serviceTypes().show();
      const paymentsList = await API.payments().getPayments();

      next(vm => {
        vm.selects.serviceTypes = serviceTypes;
        vm.selects.authors = authorList;
        vm.selects.payments = paymentsList;
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.payments().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        const authorList = await API.payments().getAuthors();
        const paymentsList = await API.payments().getPayments();

        this.setServerResponse(response);
        this.selects.authors = authorList;
        this.selects.payments = paymentsList;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.payments().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (newFilters.hasOwnProperty("author")) {
        newFilters.author = newFilters.author
          .split(",")
          .map((item: number) => Number(item));
      }

      if (newFilters.hasOwnProperty("type")) {
        newFilters.type = newFilters.type
          .split(",")
          .map((item: number) => Number(item));
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          width: "100px",
          align: "center",
          sortable: false
        }
      ];
      this.items = items?.data;

      this.totalItems = items.total;
    },
    showContextMenu(e: any, item: any) {
      this.activeItemId = item.item.id;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    }
  }
});
